<!--<i class="material-icons" [tippy]="template" [tippyOptions]="tippyOptions">more_horiz</i>-->
<!--<i class="material-icons" button>more_horiz</i>-->
<div class="context-toggle" [tippy]="template" [tippyOptions]="disabled ? disabledTippyOptions : tippyOptions">
  <ng-content select="[button]"></ng-content>
</div>

<ng-template #template>
  <div class="context-menu">
    <ng-container *ngFor="let action of actions">
      <div (click)="handleAction(action, $event)" *ngIf="action.callback"
           [class]="(action.classes ? action.classes : '') + (!action.isEnabled ? ' disabled' : '') + ' context-menu-action'"
           [title]="action.text">
        <span *ngIf="action.text" class="action-text">{{ action.text }}</span>
        <i *ngIf="action.maticon"
           [ngClass]="action.maticonStyle ? 'material-icons-' + action.maticonStyle : 'material-icons'"
           class="action-icon">{{ action.maticon }}</i>
        <span *ngIf="action.icon" [inlineSVG]="'/assets/img/' + action.icon" class="action-icon"></span>
      </div>
      <a (click)="handleAction(action, $event)"
         *ngIf="action.link && action.link.inner"
         [class]="(action.classes ? action.classes : '') + (!action.isEnabled ? ' disabled' : '') + ' context-menu-action'"
         [routerLink]="action.link.href"
         [target]="action.link.target ? action.link.target : '_parent'"
         [title]="action.text">
        <span *ngIf="action.text" class="action-text">{{ action.text }}</span>
        <i *ngIf="action.maticon" class="material-icons action-icon">{{ action.maticon }}</i>
        <span *ngIf="action.icon" [inlineSVG]="'/assets/img/' + action.icon" class="action-icon"></span>
      </a>
      <a (click)="handleAction(action, $event)"
         *ngIf="action.link && !action.link.inner"
         [class]="(action.classes ? action.classes : '') + (!action.isEnabled ? ' disabled' : '') + ' context-menu-action'"
         [href]="action.link.href"
         [target]="action.link.target ? action.link.target : '_parent'"
         [title]="action.text">
        <span *ngIf="action.text" class="action-text">{{ action.text }}</span>
        <i *ngIf="action.maticon" class="material-icons action-icon">{{ action.maticon }}</i>
        <span *ngIf="action.icon" [inlineSVG]="'/assets/img/' + action.icon" class="action-icon"></span>
      </a>
    </ng-container>
  </div>
</ng-template>
