import {Component, ElementRef, forwardRef} from '@angular/core';
import {AutocompleteComponent} from "../autocomplete/autocomplete.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-autocomplete-with-custom',
  templateUrl: './autocomplete-with-custom.component.html',
  styleUrls: ['./autocomplete-with-custom.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteWithCustomComponent), multi: true}]
})
export class AutocompleteWithCustomComponent<T extends { toString: () => string, constructor: (value: string) => T | string }> extends AutocompleteComponent {
  constructor(element: ElementRef) {
    super(element);
  }

  hasPerfectMatch = false;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.input.valueChanges.subscribe(v => {
      this.hasPerfectMatch = this.options && this.options.length && this.displayedOptions.findIndex(o => o.toString().toLowerCase() === v.toLowerCase()) > -1;
    });
  }

  selectCustomOption() {
    const option = this.input.value;
    if (!this.multiple) {
      this.result = option;
      this.input.setValue(option);
    } else {
      if (this.result === null || this.result === undefined) {
        this.result = [];
      }
      (this.result as string[]).push(option);
      this.inputVisible = false;
      this.input.reset('', {emitEvent: false});
    }
    if (this.onChange) {
      this.onChange(this.result);
    }
    this.Open = false;
  }
}
