<ng-container *ngIf="multiple && isResultAnArray(result) && result.length">
  <!--suppress TypeScriptValidateTypes -->
  <div *ngFor="let res of result; let index = index;" class="result-element">
    <ng-container [ngTemplateOutletContext]="{option: res, index}"
                  [ngTemplateOutlet]="tagTemplate || defaultTagTemplate"></ng-container>
    <i (click)="removeFromResult(index)" *ngIf="!disabled" class="material-icons remove-result">remove</i>
  </div>
</ng-container>

<div *ngIf="(multiple && showControls) || inputVisible" class="controls">
  <i (click)="inputVisible = true" *ngIf="multiple && showControls && !inputVisible"
     class="control material-icons">add</i>
  <div *ngIf="inputVisible" class="autocomplete-text-input">
    <input #textInput [formControl]="input" [placeholder]="placeholder || ''" tabindex="0" type="text">
    <i (click)="toggleList(textInput)" class="material-icons">{{ Open ? 'expand_less' : 'expand_more' }}</i>
    <ul #listElement *ngIf="Open" [class.open-top]="OpenTop" class="autocomplete-list">
      <li (click)="selectOption(option)" *ngFor="let option of displayedOptions; let index = index">
        <ng-container [ngTemplateOutletContext]="{option, index}"
                      [ngTemplateOutlet]="listElementTemplate || defaultListElementTemplate"></ng-container>
        <ng-container *ngIf="multiple">
          <i *ngIf="!this.result?.includes(option); else check"
             class="material-icons">add</i>
          <ng-template #check><i class="material-icons green">done</i></ng-template>
        </ng-container>
      </li>
      <li (click)="selectCustomOption()"
          *ngIf="input.value !== '' && input.value !== null && input.value !== undefined && !hasPerfectMatch">
        <span *ngIf="multiple; else use" class="grey" i18n>Add „{{ input.value }}”</span>
        <ng-template #use><span class="grey" i18n>Use „{{ input.value }}”</span></ng-template>
      </li>
    </ul>
  </div>
</div>

<ng-template #defaultListElementTemplate let-option="option">
  {{ option }}
</ng-template>
<ng-template #defaultTagTemplate let-option="option">
  {{ option }}
</ng-template>
