<div [class.disabled]="disabled" [class.open]="isOpen" class="dropdown-container">
  <span (click)="toggleDropdown()" *ngIf="!placeholderIcon" class="dropdown-placeholder">{{ placeholder }}</span>
  <span (click)="toggleDropdown()" *ngIf="placeholderIcon" class="dropdown-placeholder-icon">
    <img [src]="'assets/img/' + placeholderIcon">
  </span>
  <span (click)="toggleDropdown()" class="dropdown-title">{{ Title }}</span>
  <span (click)="toggleDropdown()" class="icon dropdown-arrow arrow material-icons">keyboard_arrow_down</span>
  <ul class="dropdown-body">
    <li (click)="selectAll()" *ngIf="maxSelectedItems === undefined || maxSelectedItems < 0" class="select-all">
      <span class="title" i18n="Multiselect dropdown - select all">Select all</span>
      <span *ngIf="IsAllSelected" class="icon">
        <img src="assets/img/check_mark_simple.svg"></span>
    </li>
    <li (click)="toggleElement(element)" *ngFor="let element of elements" [class.selected]="element.selected"
        class="elements">
      <span class="title">{{ element.title }}</span>
      <span *ngIf="element.selected" class="icon">
        <img src="assets/img/check_mark_simple.svg"></span>
    </li>
  </ul>
</div>
