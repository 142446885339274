<section class="content">
  <div [class.has-error]="formGroup.hasError('form') && formGroup.dirty" class="logo">
    <img src="assets/img/logo.svg" alt="logo">
  </div>
  <form (ngSubmit)="login()" [formGroup]="formGroup" class="input-container">
    <div class="input-row">
      <label for="email" i18n="Login e-mail label">E-mail</label>
      <input [formControlName]="'email'" id="email" name="email" type="email">
    </div>
    <div class="input-row">
      <label for="password" i18n="Login password label">Password</label>
      <input [formControlName]="'password'" id="password" name="password" type="password">
    </div>
    <div *ngIf="formGroup.hasError('form') && formGroup.dirty" class="error">Invalid e-mail/password</div>
    <button [disabled]="formGroup.invalid" class="login-button" i18n="Login form submit" type="submit">Login</button>
  </form>
</section>
