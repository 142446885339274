<ng-template #defTemplate let-tag><span class="default-template">{{ tag }}</span></ng-template>

<div class="tag" *ngFor="let tag of selectedTags">
  <ng-container *ngTemplateOutlet="tagTemplate ? tagTemplate : defTemplate; context: {$implicit: tag}"></ng-container>
  <i *ngIf="!disabled" class="material-icons" (click)="removeTag(tag)">remove</i>
</div>

<i
  *ngIf="!disabled && !hideControls && (limit === null || selectedTags?.length < limit) && !isAdding && !(addingModeWhenEmpty && selectedTags?.length === 0)"
  class="material-icons add" (click)="add()">add</i>
<div *ngIf="isAdding || (addingModeWhenEmpty && (!selectedTags || selectedTags?.length === 0))" class="add-controls">
  <app-dropdown (cancelled)="isAdding = false" *ngIf="inputType === 'dropdown'" [(ngModel)]="tagToAdd"
                [disabled]="disabled || (limit !== null && selectedTags.length >= limit)"
                [list]="UnaddedTags" [placeholder]="placeholder || 'Choose an item'"
                [template]="tagTemplate ? tagTemplate : defTemplate" i18n-placeholder></app-dropdown>
  <input type="text" *ngIf="inputType === 'text'" [(ngModel)]="tagToAdd" (keydown.enter)="addSelectedTag()"
         [placeholder]="placeholder || 'Type new item'" i18n-placeholder/>
  <app-typeahead *ngIf="inputType === 'typeahead'" [placeholder]="placeholder" [displayTemplate]="typeaheadWrapper"
                 [options]="tagList" [ngModel]="tagToAdd" [selectOnly]="typeaheadSelectOnly" [displayWith]="typeaheadDisplayWith"
                 (ngModelChange)="typeaheadTagSelected($event)"></app-typeahead>
  <i class="material-icons" (click)="addSelectedTag()">check</i>
  <i class="material-icons" (click)="cancelAdd()">close</i>
</div>

<ng-template #typeaheadWrapper let-option="option" let-idx="idx">
  <ng-container *ngTemplateOutlet="tagTemplate ? tagTemplate : defTemplate; context: {option, idx}"></ng-container>
</ng-template>
