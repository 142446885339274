import {Component, OnDestroy} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Component({
  selector: 'app-barcode-base',
  template: ``
})
export class BarcodeBaseComponent implements OnDestroy {
  private barcode = '';
  private readonly sub: Subscription;

  constructor() {
    this.sub = fromEvent(document, 'keydown').subscribe((event: Event) => {
      const key = (event as KeyboardEvent).key;
      if (key === 'Enter') {
        this.handleBarcode(this.barcode);
      } else if (/\b[a-z0-9]\b/i.test(key)) {
        this.barcode += key;
        this.barcode = this.barcode.substring(this.barcode.length - 8);
      }
    });
  }

  handleBarcode(barcode: string): void {
    this.barcode = '';
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
