import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {AsyncSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {UserService} from '@innobile/authmodule';
import {CompanyUser} from '../models/CompanyUser';
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  /**
   * Socket-ekből álló tömb. Névtér alapján indexelve, így a már létrehozott kapcsolatot is vissza tudjuk adni ismételt
   * lekérés esetén
   */
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _sockets: { [namespace: string]: AsyncSubject<SocketIOClient.Socket> } = {};
  /**
   * Adott névtérhez tartozó socket-et használók száma
   */
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _socketCounters: { [namespace: string]: number } = {};
  /**
   * Hitelesítési hibát jelölő flag - felesleges ismételt lekérések elkerülésére
   */
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _authError = false;

  private user: CompanyUser;

  /**
   * Konstruktor
   */
  constructor(private userService: UserService, private languageService: LanguageService) {
    this.userService.userSubject.subscribe((user: CompanyUser) => {
      this.user = user;
    });
  }

  /**
   * Csatlakozás a paraméterként kapott nevű socket.io névtérhez
   * @param namespace - névtér
   * @returns - socket-et tartalmazó observable
   */
  public joinNamespace(namespace: string): Observable<SocketIOClient.Socket> {
    return new Observable<SocketIOClient.Socket>(observer => {
      if (this._sockets.hasOwnProperty(namespace)) {
        this._socketCounters[namespace]++;
        this._sockets[namespace].subscribe(socket => {
          observer.next(socket);
        });
      } else {
        this._sockets[namespace] = new AsyncSubject<SocketIOClient.Socket>();
        this.connect(namespace).then(socket => {
          observer.next(socket);
        }).catch(error => {
          observer.error(error);
        });
      }
      return () => {
        this.leaveNamespace(namespace);
      };
    });
  }

  /**
   * Socket névtér elhagyása
   * @param namespace - névtér
   */
  public leaveNamespace(namespace: string) {
    if (this._socketCounters.hasOwnProperty(namespace) && this._socketCounters[namespace] === 1) {
      window.setTimeout(() => {
        this._sockets[namespace].subscribe(socket => {
          socket.disconnect();
        });
      }, 1000);
    } else {
      this._socketCounters[namespace]--;
    }
  }

  /**
   * Csatlakozás az adott névtérhez
   * @param namespace - névtér
   * @returns - socket-et tartalmazó Promise
   */
  private async connect(namespace: string): Promise<SocketIOClient.Socket> {
    const socketPath = environment.socketPrefix + namespace;
    let reconnectAttempts = 0;
    return new Promise<SocketIOClient.Socket>((resolve, reject) => {
      const innerConnect = () => {
        const socket = io(socketPath, {
          reconnection: false,
          transportOptions: {
            polling: {}
          }
        });

        const timeout = setTimeout(() => {
          if (reconnectAttempts > 3) {
            console.error('Socket timed out after ' + reconnectAttempts + ' attempts on ' + socketPath);
            reject('Socket timed out after ' + reconnectAttempts + ' attempts on ' + socketPath);
          } else {
            reconnectAttempts++;
            console.warn('Socket timed out, reconnection attempt #' + reconnectAttempts + ' on ' + socketPath);
            socket.close();
            innerConnect();
          }
        }, 5000);

        console.log('Connecting to socket ' + socketPath);
        socket.on('connect', () => {
          clearTimeout(timeout);
          // TODO: send company id
          socket.emit('authenticate', {
            token: localStorage.getItem('token'),
            language: this.languageService.Language,
            companies: this.user.SelectedCompanies
          })
            .on('authenticated', () => {
              this._sockets[namespace].next(socket);
              this._sockets[namespace].complete();
              this._socketCounters[namespace] = 1;
              console.log('Socket authenticated on ' + socketPath);
              resolve(socket);
            })
            .on('unauthorized', msg => {
              console.error('Socket authentication failed. Error: ', msg);
              this._authError = true;
              reject('Socket authentication failed on ' + socketPath + ' Error: ' + msg);
            })
            .on('disconnect', (data) => {
              delete this._sockets[namespace];
              delete this._socketCounters[namespace];
              console.warn('Socket disconnected on ' + socketPath + ' with : ' + data);
            });
        }).on('error', (data) => {
          console.error('Socket error: ' + socketPath, data);
          reject('Socket error: ' + socketPath + ' :' + data);
        }).on('connect_failed', data => {
          console.error('Socket connection error' + socketPath, data);
          reject('Socket connection error ' + socketPath + ' :' + data);
        }).on('reconnect_failed', data => {
          console.error('Socket reconnection failed ' + socketPath, data);
          reject('Socket reconnection failed ' + socketPath + ' :' + data);
        });
      };
      innerConnect();
    });
  }
}
