import {Injectable} from '@angular/core';

/**
 * Alkalmazásban komponensek / modulok közötti megosztott változók
 */
@Injectable()
export class SharedVariablesService {
  /**
   * Változók
   */
  private variables = {};

  /**
   * Konstruktor
   */
  constructor() {
  }

  /**
   * Új változó hozzáadása / módosítása
   * @param key - változó egyedi azonosítója
   * @param value - érték
   */
  public setVar<T>(key: string, value: T): void {
    this.variables[key] = value;
  }

  /**
   * Visszaadja a változót
   * @param key - változó egyedi azonosítója
   * @returns - változó értéke
   */
  public getVar<T>(key: string): T {
    if (!this.variables.hasOwnProperty(key)) {
      return null;
    } else {
      return this.variables[key];
    }
  }

  public hasVar(key: string) {
    return this.variables.hasOwnProperty(key);
  }
}
