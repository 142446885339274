import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => AutocompleteComponent)}]
})
export class AutocompleteComponent<T extends { toString: () => string } = string> implements OnInit, ControlValueAccessor, AfterViewInit {
  /** If multiple = true, the list of selected options will be displayed using this template */
  @Input() tagTemplate?: TemplateRef<{ option: T, index: number }>;
  /** The options will be displayed using this template */
  @Input() listElementTemplate?: TemplateRef<{ option: T, index: number }>;
  @Input() placeholder?: string;
  @Input() multiple = false;
  @Input() showControls = true;
  // @Input() allowCustom = false;
  /** The component will use this method to filter the list of options, and will use the resulting string in the ngModel
   * Must be used if the options are not of string type */
  /*@Input() resultFormatter?: (item: T) => string = (item) => {
    throw 'No resultFormatter was supplied.';
    return item.toString();
  }*/
  /** List of options */
  @Input() options?: T[] = [];
  @HostBinding('class.disabled') disabled = false;
  @ViewChild('listElement') private listElement: ElementRef<HTMLUListElement>;
  displayedOptions: T[] = [];
  input = new UntypedFormControl('');
  inputVisible = false;

  result: T | T[] | null = null;
  private _open = false;

  constructor(private element: ElementRef) {
  }

  onChange?: (value: T | T[] | null) => {};
  onTouched?: () => {};

  get OpenTop(): boolean {
    if (this.listElement) {
      const rect = this.listElement.nativeElement.getBoundingClientRect();
      return (rect.top + rect.height) >= window.innerHeight;
    }
    return false;
  }

  get Open(): boolean {
    return this._open;
  }

  set Open(to: boolean) {
    if (!to && this.onTouched) {
      this.onTouched();
    }
    if (to && (this.input.value === '' || this.input.value === null || this.input.value === undefined)) {
      if (this.options.length) {
        this.displayedOptions = this.options;
      }
    }
    this._open = to;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.input.disable();
    } else {
      this.input.enable();
    }
  }

  writeValue(res: T | T[] | null): void {
    if (typeof res === 'string' && this.multiple) {
      throw 'Incompatible type - expected string[], got string. Change the supplied ngModel or set @Input() multiple to false';
    } else if (Array.isArray(res) && !this.multiple) {
      throw 'Incompatible type - expected string, got Array. Change the supplied ngModel or set @Input() multiple to true';
    } else {
      this.result = res;
      if (!this.multiple) {
        this.input.setValue(this.result, {emitEvent: false});
      }
    }
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: T | T[] | null) => {}): void {
    this.onChange = fn;
  }

  @HostListener('document:click', ['$event']) outClick(event: MouseEvent) {
    if ((!this.element.nativeElement.contains(event.target))) {
      this.Open = false;
    }
  }

  ngOnInit() {
    this.inputVisible = !this.multiple;

  }

  selectOption(option: T) {
    if (!this.multiple) {
      this.result = option;
      this.input.setValue(option);
    } else {
      if (this.result === null || this.result === undefined) {
        this.result = [option]
      } else {
        const idx = (this.result as T[]).indexOf(option);
        if (idx > -1) {
          (this.result as T[]).splice(idx, 1);
        } else {
          (this.result as T[]).push(option);
        }
      }
      this.inputVisible = false;
      this.input.reset('', {emitEvent: false});
    }
    if (this.onChange) {
      this.onChange(this.result);
    }
    this.Open = false;
  }

  clear() {
    if (!this.disabled) {
      this.result = null;
      if (this.onChange) {
        this.onChange(this.result);
      }
    }
  }

  isResultAnArray(result: T | T[] | null): result is Array<T> {
    return Array.isArray(result);
  }

  removeFromResult(idx: number) {
    (this.result as T[]).splice(idx, 1);
    if (this.onChange) {
      this.onChange(this.result);
    }
  }

  toggleList(textInput: HTMLInputElement) {
    if (!this.disabled) {
      const to = !this.Open;
      this.Open = to;
      if (to) {
        textInput.focus();
      }
    }
  }

  ngAfterViewInit(): void {
    this.input.valueChanges.subscribe(v => {
      if (this.options.length) {
        if (v === '' || v === null) {
          this.displayedOptions = this.options;
        } else {
          this.displayedOptions = this.options.filter(o => o.toString().toLowerCase().includes(v.toString().toLowerCase()));
        }
      }
      this.Open = true;
    });
  }
}
