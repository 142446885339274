<div (click)="overlayClicked()" *ngIf="template" class="popup-overlay">
  <div (click)="handlePopupClick($event)" class="popup">
    <span class="title" i18n>Tag replace</span>
    <div class="tabs">
      <span
        [class.active]="template === PopupTemplate.IdentifyTag1 || template === PopupTemplate.UnknownTag || template === PopupTemplate.NotAssigned || template === PopupTemplate.OnlyWorker"
        class="tab"
        i18n>1. Read old tag</span>
      <span
        [class.active]="template === PopupTemplate.IdentifyTag2 || template === PopupTemplate.UnknownTag2 || template === PopupTemplate.Assigned || template === PopupTemplate.OnlyWorker2"
        class="tab"
        i18n>2. Read new tag</span>
      <span [class.active]="template === PopupTemplate.Success" class="tab" i18n>3. Finish</span>
    </div>
    <span *ngIf="template === PopupTemplate.UnknownTag" class="text" i18n>Couldn't identify the tag, because the tag ID is unknown. Please select another tag!</span>
    <span *ngIf="template === PopupTemplate.NotAssigned" class="text" i18n>The tag is successfully identified, but it's currently not assigned. Please select another tag!</span>
    <span *ngIf="template === PopupTemplate.OnlyWorker" class="text" i18n>The tag is successfully identified, but it's only for worker tracking. Please select another tag!</span>
    <span *ngIf="template === PopupTemplate.Success" class="text" i18n>The tag replacement is successful. Please place the old tag on the charger!</span>

    <span
      *ngIf="template === PopupTemplate.IdentifyTag2 || template === PopupTemplate.Assigned || template === PopupTemplate.OnlyWorker2 || template === PopupTemplate.UnknownTag2"
      class="text" i18n>The tag is successfully identified. The tag is assigned to this unit:</span>

    <div *ngIf="tag" [class.success]="template === PopupTemplate.Success" class="tag">
      <div class="upper">
        <img alt="tag-icon" class="tag-icon" src="assets/img/tag_filled.svg"/>
        <span class="tag-name">{{ tag.name }}</span>

        <ng-container *ngIf="tag.assignment">
          <div>
            <img alt="unit-icon" src="assets/img/unit.svg"/>
            <span>{{ tag.assignment.identifier }}</span>
          </div>
          <div>
            <i class="material-icons-round">grid_view</i>
            <span>{{ tag.assignment.quantity }}</span>
          </div>
        </ng-container>
        <div class="battery">
          <img [src]="'assets/img/battery-' + (tag.battery < 33 ? '20' : (tag.battery < 66 ? '50' : '80'))+ '.svg'"
               alt="unit-icon"/>
          <span>{{ tag.battery }}%</span>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="template">
      <span *ngSwitchCase="PopupTemplate.UnknownTag2" class="text" i18n>Couldn't identify the tag, because the tag ID is unknown. Please select another tag!</span>
      <span *ngSwitchCase="PopupTemplate.Assigned" class="text" i18n>The tag is successfully identified, but it's currently assigned. Please select another tag!</span>
      <span *ngSwitchCase="PopupTemplate.OnlyWorker2" class="text" i18n>The new tag is successfully identified, but it's only for worker tracking. Please select another tag!</span>
    </ng-container>

    <div *ngIf="template === PopupTemplate.IdentifyTag1 || template === PopupTemplate.IdentifyTag2" class="tag-search">
      <div class="left">
        <span *ngIf="template === PopupTemplate.IdentifyTag1" class="text" i18n>
          Scan the QR code on the tag you want to replace with the handheld reader!
        </span>
        <span *ngIf="template === PopupTemplate.IdentifyTag2" class="text" i18n>
          Scan the QR code on the new tag with the handheld reader!
        </span>
        <img alt="tag-image" src="assets/img/tag-scan.png">
      </div>
      <div class="right">
        <span class="text" i18n>
          If you can't read the QR code, enter the identifier from the tag!
        </span>
        <span class="text-bottom" i18n>
          The identifier is 8 character long and can be found under the QR code.
        </span>
        <input *ngIf="template === PopupTemplate.IdentifyTag1" [(ngModel)]="tagIdentifier" class="input"
               placeholder="AAAA1234" type="text">
        <input *ngIf="template === PopupTemplate.IdentifyTag2" [(ngModel)]="tagIdentifier2" class="input"
               placeholder="AAAA1234" type="text">

        <div class="bottom">
          <button (click)="identify(1)" *ngIf="template === PopupTemplate.IdentifyTag1"
                  [disabled]="tagIdentifier.length < 5"
                  class="green with-icon">
            <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Identify</span>
          </button>
          <button (click)="identify(2)" *ngIf="template === PopupTemplate.IdentifyTag2"
                  [disabled]="tagIdentifier2.length < 5"
                  class="green with-icon">
            <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Identify</span>
          </button>
          <button (click)="close()" class="with-icon light">
            <i class="material-icons">close</i><span i18n>Cancel</span>
          </button>
        </div>
      </div>
    </div>


    <div *ngIf="template !== PopupTemplate.IdentifyTag1 && template !== PopupTemplate.IdentifyTag2" class="bottom">
      <button (click)="selectAnother(1)"
              *ngIf="template === PopupTemplate.UnknownTag || template === PopupTemplate.NotAssigned || template === PopupTemplate.OnlyWorker"
              class="with-icon light">
        <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Select another tag</span>
      </button>
      <button (click)="selectAnother(2)"
              *ngIf="template === PopupTemplate.UnknownTag2 || template === PopupTemplate.Assigned || template === PopupTemplate.OnlyWorker2"
              class="with-icon light">
        <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Select another tag</span>
      </button>
      <button (click)="close()"
              *ngIf="template === PopupTemplate.UnknownTag || template === PopupTemplate.NotAssigned || template === PopupTemplate.OnlyWorker || template === PopupTemplate.UnknownTag2 || template === PopupTemplate.Assigned || template === PopupTemplate.OnlyWorker2"
              class="with-icon light">
        <i class="material-icons">close</i><span i18n>Cancel</span>
      </button>
      <button (click)="goDetails()" *ngIf="template === PopupTemplate.Success" class="with-icon light">
        <i class="material-icons">list</i><span i18n>Details</span>
      </button>
      <button (click)="goTracking()" *ngIf="template === PopupTemplate.Success" class="with-icon light">
        <i class="material-icons">gps_not_fixed</i><span i18n>Tracking</span>
      </button>
      <button (click)="close()" *ngIf="template === PopupTemplate.Success" class="with-icon green pull-right">
        <i class="material-icons">arrow_forward</i><span i18n>Finish</span>
      </button>
    </div>
  </div>
</div>
