import {Component, HostBinding, Input} from '@angular/core';
import {ContextMenuAction} from './context-menu-action';
import {hideAll} from 'tippy.js';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @Input() actions: ContextMenuAction[];
  @Input() disabled = false;

  @HostBinding('class.disabled') get dc() {
    return this.disabled;
  }

  tippyOptions = {
    theme: 'pozi',
    interactive: true,
    trigger: 'click',
    appendTo: document.body,
    placement: 'bottom',
    ignoreAttributes: true
  };

  disabledTippyOptions = {...this.tippyOptions, ...{trigger: 'manual'}};

  constructor() {
  }

  handleAction(action: ContextMenuAction, event: Event) {
    if (action.link && !action.isEnabled) {
      event.preventDefault();
    }
    if (action.callback) {
      if (action.isEnabled) {
        action.callback();
      }
    }
    hideAll();
  }
}
