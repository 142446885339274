import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors
} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import tippy, {Placement} from 'tippy.js';
import * as _ from 'lodash';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TypeaheadComponent)
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => TypeaheadComponent)
  }]
})
export class TypeaheadComponent<T> implements ControlValueAccessor, OnDestroy {
  onChange?: (model: any) => any;
  onTouched?: () => any;
  touched = false;
  formControl = new UntypedFormControl('');
  selectedValue: T | string | null = null;
  displayedOptions: T[] = [];
  focusedOption: number | null = null;
  optionListView?: EmbeddedViewRef<any>;
  open = false;
  tippyOptions = {
    theme: 'pozi',
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start' as Placement,
    offset: [0, 0] as [number, number],
    arrow: false,
    onShown: () => {
      this.open = true;
    },
    onHidden: () => {
      this.open = false;
    }
  };
  private tippyInstance: any = null;
  @HostBinding('class.disabled') disabled = false;
  @ViewChild('optionList') optionListTemplate?: TemplateRef<any>;
  @Input() options: T[] = [];
  @Input() selectOnly = false;
  @Input() placeholder?: string;
  @Input() displayWith: ((value: any) => string) = (value: any) => {
    return value ? value.toString() : ''
  };
  @Input() displayTemplate: TemplateRef<{ option: T, idx: number }>;
  @Input() createNew?: (value: string) => T;

  @HostListener('keydown', ['$event']) keyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case 'Escape':
        this.resetForm();
        event.preventDefault();
        break;
      case 'Enter':
        this.selectValue(this.formControl.value);
        this.tippyInstance.hide();
        event.preventDefault();
        break;
      case 'ArrowUp':
        this.focusOption(!this.focusedOption ? this.displayedOptions.length - 1 : this.focusedOption - 1);
        event.preventDefault();
        break;
      case 'ArrowDown':
        this.focusOption(this.focusedOption === null || this.focusedOption === this.displayedOptions.length - 1 ?
          0 : this.focusedOption + 1);
        event.preventDefault();
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        break;
      default:
        this.focusedOption = null;
        break;
    }
  }

  constructor(private el: ElementRef) {
    this.formControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.drawTippy(false);
    });
  }

  selectValue(newVal: any): void {
    if (this.focusedOption !== null) {
      if (this.selectedValue !== this.displayedOptions[this.focusedOption]) {
        this.selectedValue = this.displayedOptions[this.focusedOption];
        this.formControl.setValue(this.displayWith(this.selectedValue), {emitEvent: false});
        this.focusedOption = null;
        this.tippyInstance.hide();
      }
    } else if (!this.selectOnly) {
      if (newVal !== this.selectedValue) {
        this.selectedValue = this.createNew ? this.createNew(newVal) : newVal;
        this.formControl.setValue(this.displayWith(newVal));
        this.tippyInstance.hide();
      }
    } else if (this.displayedOptions.includes(newVal)) {
      this.selectedValue = newVal;
      this.formControl.setValue(this.displayWith(this.selectedValue), {emitEvent: false});
      this.focusedOption = null;
      this.tippyInstance.hide();
    } else {
      this.resetForm();
      return;
    }
    if (!this.touched) {
      this.touched = true;
      if (this.onTouched) {
        this.onTouched();
      }
    }
    if (this.onChange) {
      this.onChange(this.selectedValue);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.disabled ? this.formControl.disable() : this.formControl.enable();
  }

  writeValue(obj: any): void {
    this.formControl.setValue(this.displayWith(obj));
  }

  validate({value}: UntypedFormControl): ValidationErrors | null {
    return this.selectOnly && value !== null && (this.options && !this.options.some(o => _.isEqual(o, value))) ? {invalidValue: true} : null;
  }

  private resetForm(): void {
    this.focusedOption = null;
    this.formControl.setValue(this.selectedValue ? this.displayWith(this.selectedValue) : '', {emitEvent: false});
    this.tippyInstance.hide();
  }

  ngOnDestroy(): void {
    if (this.tippyInstance) {
      this.tippyInstance.hide();
    }
  }

  focusOption(option: number): void {
    if (this.focusedOption !== option) {
      this.focusedOption = option;
      this.drawTippy();
      this.tippyInstance.props.content.children[this.focusedOption].scrollIntoView(false);
    }
  }

  drawTippy(show = true, hide = false): void {
    if (this.options && this.optionListTemplate !== undefined) {
      this.displayedOptions = this.formControl.value === null ? this.options : this.options.filter(o => this.displayWith(o).toLowerCase().includes(this.formControl.value.toLowerCase()));
      this.optionListView = this.optionListTemplate.createEmbeddedView({$implicit: this.displayedOptions});
      this.optionListView.detectChanges();
      if (this.focusedOption !== null) {
        this.optionListView?.rootNodes[0].children[this.focusedOption].scrollIntoView();
      }
      if (!this.tippyInstance) {
        this.tippyInstance = tippy(this.el.nativeElement, {
          ...this.tippyOptions,
          content: this.optionListView.rootNodes[0],
          allowHTML: true
        });
      } else {
        if (hide) {
          this.tippyInstance.hide();
        }
        this.tippyInstance.setContent(this.optionListView.rootNodes[0]);
      }
      if (show) {
        this.tippyInstance.show();
      }
    }
  }

  selectTyped() {
    this.selectValue(this.formControl.value);
    this.tippyInstance.hide();
  }

  toggle() {
    if (this.open) {
      this.tippyInstance.hide();
    } else {
      if (this.tippyInstance === null) {
        this.drawTippy(false);
      }
      this.tippyInstance.show();
    }
  }
}
