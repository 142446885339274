import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {IDropdownElement} from '../dropdown/dropdown-element.interface';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent {
  private dirty = false;

  @Input() elements: IDropdownElement[] = [];
  @Output() elementsChange = new EventEmitter<IDropdownElement[]>();
  @Input() placeholder = '';
  @Input() placeholderIcon: string;
  @Input() disabled = false;
  @Input() maxSelectedItems?: number;

  isOpen = false;

  @HostListener('document:click', ['$event']) outClick(event: MouseEvent) {
    if ((!this.element.nativeElement.contains(event.target))) {
      this.isOpen = false;
      if (this.dirty) {
        this.elementsChange.emit(this.elements);
        this.dirty = false;
      }
    }
  }

  constructor(private element: ElementRef) {
  }

  get Title(): string {
    const selected = this.elements.filter(elem => elem.selected);

    return selected.length === 1 ? selected[0].title : selected.length + ' ' +
      ($localize`:@@dropdown.selected:selected`);
  }

  get IsAllSelected(): boolean {
    return this.elements.every(elem => elem.selected);
  }

  toggleDropdown() {
    if (!this.disabled) {
      if (this.dirty) {
        this.elementsChange.emit(this.elements);
        this.dirty = false;
      }
      this.isOpen = !this.isOpen;
    }
  }

  toggleElement(element: IDropdownElement) {
    if (typeof this.maxSelectedItems !== 'undefined' && this.maxSelectedItems > -1) {
      if (element.selected || this.elements.filter(e => e.selected).length < this.maxSelectedItems) {
        element.selected = !element.selected;
        this.dirty = true;
      } else if (this.maxSelectedItems === 1) {
        // allow simple dropdown component functionality if limit === 1
        this.elements.forEach(e => e.selected = false);
        element.selected = true;
        this.dirty = true;
      }
    } else {
      element.selected = !element.selected;
      this.dirty = true;
    }
  }

  selectAll() {
    if (typeof this.maxSelectedItems !== 'undefined' && this.maxSelectedItems > -1) {
      return;
    }
    const isAllSelected = this.IsAllSelected;
    this.elements.forEach(elem => {
      elem.selected = !isAllSelected;
    });
    this.dirty = true;
  }

}
