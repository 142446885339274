import {Component, forwardRef, HostBinding, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-tri-state-switch',
  styleUrls: ['./tri-state-switch.component.scss'],
  template: '',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TriStateSwitchComponent)
  }]
})
export class TriStateSwitchComponent implements ControlValueAccessor {
  onChange: (value: boolean | null) => void;
  onTouched: () => void;
  @Input() indeterminateValue = null;
  value: boolean | null = null;
  @HostBinding('class.disabled') disabled = false;

  @HostBinding('class.on') get isOn() {
    return this.value === true;
  }
  @HostBinding('class.indeterminate') get isInter() {
    return this.value === this.indeterminateValue;
  }

  @HostListener('click') click() {
    if (!this.disabled) {
      this.value = !this.value;
      if (this.onTouched) {
        this.onTouched();
      }
      if (this.onChange) {
        this.onChange(this.value);
      }
    }
  }

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: boolean | null): void {
    this.value = obj;
  }

}
