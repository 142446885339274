import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagReplaceService {
  private currentTag: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor() {
  }

  public open(tag: string) {
    this.currentTag.next(tag);
  }

  public close() {
    this.currentTag.next(null);
  }

  public getCurrentTag(): Observable<string | null> {
    return this.currentTag.asObservable();
  }
}
