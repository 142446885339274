import {Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() checked: boolean | 'indeterminate' = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  @HostBinding('class.checked') _checked = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  @HostBinding('class.indeterminate') _indeterminate = false;

  constructor() {
  }

  @HostListener('click') click() {
    this.checked = this.checked !== true;
    this.checkedChange.emit(this.checked);
    this.setClasses();
  }

  ngOnInit() {
    this.setClasses();
  }

  ngOnChanges(): void {
    this.setClasses();
  }

  private setClasses() {
    this._checked = this.checked === true;
    this._indeterminate = this.checked === 'indeterminate';
  }

}
