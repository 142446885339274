import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @HostListener('document:keydown.escape', ['$event']) keyPress() {
    if (this.visible) {
      this.close();
    }
  }

  constructor() {
  }

  close() {
    this.visibleChange.emit(this.visible = false);
  }

  click(event: MouseEvent) {
    if ((event.target as HTMLElement).className === 'popup-overlay') {
      this.close();
    }
  }
}
