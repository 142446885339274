<section class="content">
  <div [class.has-error]="formGroup.hasError('form') && formGroup.dirty" class="logo">
    <img src="assets/img/logo.svg" alt="logo">
  </div>
  <form (ngSubmit)="reset()" [formGroup]="formGroup" class="input-container">
    <div [class.has-error]="formGroup.controls['password'].invalid && formGroup.controls['password'].dirty"
         class="input-row">
      <label for="password" i18n="Login password label">Password</label>
      <input [formControlName]="'password'" id="password" name="password" type="password">
    </div>
    <div *ngIf="formGroup.hasError('form') && formGroup.dirty" class="error">Password reset failed due to an unknown error</div>
    <button [disabled]="formGroup.invalid" class="login-button" i18n="Reset password submit" type="submit">Reset password</button>
  </form>
</section>
