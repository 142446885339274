import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Egy elem kiválasztására alkalmas legördülő menü
 * A komponens {@link IDropdownElement} interfészt megvalósító objektumokkal dolgozik
 * @example
 * [
 *     {id: 0, title: 'példa elem'},
 *     {id: 1, title: 'példa 2'}
 * ]
 */
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DropdownComponent)
  }]
})
export class DropdownComponent implements ControlValueAccessor {
  isOpen = false;

  @Input() list: any[];
  @Input() placeholder: string;
  @Input() template: TemplateRef<any>;
  @Input() cancelable = false;
  @Output() cancelled = new EventEmitter<void>();
  disabled = false;

  selectedElement: any;

  touched = false;

  /**
   * Komponens létrehozása
   * @param element - Komponens HTML eleme
   */
  constructor(private element: ElementRef) {
  }

  onChange = (model: any) => {
  }

  onTouched = () => {
  }

  @HostListener('document:click', ['$event']) outClick(event: MouseEvent) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) cancel(event) {
    event.preventDefault();
    if (this.cancelable) {
      this.isOpen = false;
      this.cancelled.emit();
    }
  }

  /**
   * Legördülő menü megjelenítését, elrejtését valósítja meg
   */
  //noinspection JSUnusedLocalSymbols
  toggleDropdown() {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  /**
   * A paraméterként megkapott azonosítóval rendelkező elemet kiválasztja
   * @param element Kiválasztott elem
   */
  //noinspection JSUnusedLocalSymbols
  selectElement(element: any) {
    if (!this.touched && this.selectedElement !== element) {
      this.onTouched();
      this.touched = true;
    }
    this.selectedElement = element;
    this.onChange(element);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(model: any): void {
    this.selectedElement = model;
  }
}
