import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    token: new UntypedFormControl({value: '', hidden: true}, [Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });
  errMsg = $localize`:@@pwreset.error:Password reset failed due to an unknown error`;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(qpm => {
      if (qpm.has('token')) {
        this.formGroup.get('token').setValue(qpm.get('token'));
      }
    });
  }

  reset() {
    if (this.formGroup.valid) {
      this.http.put('users/reset', this.formGroup.value).subscribe(() => {
        this.router.navigate(['login']);
      }, error => {
        console.error(error);
        this.formGroup.setErrors({form: this.errMsg});
      });
    }
  }
}
