import {Injectable} from '@angular/core';
import {Row, TableIds, TableWebSocketAdapterInterface, WebSocketAdapter} from '@innobile/tablemodule';
import {Subject} from 'rxjs';
import {SocketService} from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class TableSocketAdapterService implements TableWebSocketAdapterInterface {
  adapters: { [namespace: string]: { adapter: WebSocketAdapter, counter: number } } = {};

  constructor(private socketService: SocketService) {
  }

  async joinNamespace(namespace: string): Promise<WebSocketAdapter> {
    return new Promise((resolve, reject) => {
      if (namespace in this.adapters) {
        this.adapters[namespace].counter++;
        resolve(this.adapters[namespace].adapter);
      } else {
        this.socketService.joinNamespace(namespace).subscribe(socket => {
          const tableSubject = new Subject<{ data: Row[], metadata: { pagination: any, ids: TableIds }, summary: any }>();
          {
            socket.on('table', ret => {
              tableSubject.next(ret);
            });
          }
          this.adapters[namespace] = {
            adapter: {
              setParams: (data: any) => {
                socket.emit('setTable', data);
              }, newRows: tableSubject
            }, counter: 1
          };
          resolve(this.adapters[namespace].adapter);
        });
      }
    });
  }

  leaveNamespace(namespace: string): void {
    this.socketService.leaveNamespace(namespace);
    if (namespace in this.adapters) {
      this.adapters[namespace].counter--;
      if (this.adapters[namespace].counter === 0) {
        delete this.adapters[namespace];
      }
    }
  }
}
