import {Directive, ElementRef, EmbeddedViewRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import tippy from 'tippy.js';

@Directive({
  selector: '[tippy]'
})
export class TippyDirective implements OnChanges {
  @Input('tippy') popup: TemplateRef<any>;
  // tippy.js api docs gonna be your best friend
  @Input() tippyOptions = {};
  @Input() templateContext?: {[name: string]: any} = {};

  private view: EmbeddedViewRef<any>;
  private instance;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.view = this.popup.createEmbeddedView(this.templateContext);
    this.view.detectChanges();
    this.instance = tippy(this.el.nativeElement, {...this.tippyOptions, content: this.view.rootNodes[0]});
    this.instance.context = this.templateContext;
  }
}
