<button (click)="openPopup()" class="with-icon red">
  <i class="material-icons">qr_code</i><span i18n>Tag search</span>
</button>
<div (click)="overlayClicked()" *ngIf="template" class="popup-overlay">
  <div (click)="handlePopupClick($event)" class="popup">
    <span class="title" i18n>Tag Search</span>
    <ng-container [ngSwitch]="template">
      <span *ngSwitchCase="PopupTemplate.UnknownTag" class="text" i18n>Couldn't identify the tag, because the tag ID is unknown. Please try again, or select another tag!</span>
      <span *ngSwitchCase="PopupTemplate.NotAssigned" class="text" i18n>The tag is successfully identified, but it's currently not assigned.</span>
      <span *ngSwitchCase="PopupTemplate.AssignedToWorker" class="text" i18n>The tag is successfully identified. The tag is assigned to this worker:</span>
      <span *ngSwitchCase="PopupTemplate.AssignedToUnit" class="text" i18n>The tag is successfully identified. The tag is assigned to this unit:</span>
    </ng-container>

    <div *ngIf="template === PopupTemplate.IdentifyTag" class="tag-search">
      <div class="left">
        <span class="text" i18n>
          Scan the QR code on the tag with the handheld reader!
        </span>
        <img alt="tag-image" src="assets/img/tag-scan.png">
      </div>
      <div class="right">
        <span class="text" i18n>
          If you can't read the QR code, enter the identifier from the tag!
        </span>
        <span class="text-bottom" i18n>
          The identifier is 8 character long and can be found under the QR code.
        </span>
        <input [(ngModel)]="tagIdentifier" class="input" placeholder="AAAA1234" type="text">

        <div class="bottom">
          <button (click)="identify()" [disabled]="tagIdentifier.length < 5" class="green with-icon">
            <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Identify</span>
          </button>
          <button (click)="close()" class="with-icon light">
            <i class="material-icons">close</i><span i18n>Cancel</span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="tag" class="tag">
      <div class="upper">
        <img alt="tag-icon" class="tag-icon" src="assets/img/tag_filled.svg"/>
        <span class="tag-name">{{ tag.name }}</span>

        <ng-container *ngIf="tag.unit">
          <div>
            <img alt="unit-icon" src="assets/img/unit.svg"/>
            <span>{{ tag.unit.name }}</span>
          </div>
          <div>
            <i class="material-icons-round">grid_view</i>
            <span>{{ tag.unit.quantity }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="tag.employee">
          <div>
            <i class="material-icons">accessibility_new</i>
            <span>{{ tag.employee.name }}</span>
          </div>
        </ng-container>
        <div class="battery">
          <img [src]="'assets/img/battery-' + (tag.battery < 33 ? '20' : (tag.battery < 66 ? '50' : '80'))+ '.svg'"
               alt="unit-icon"/>
          <span>{{ tag.battery }}%</span>
        </div>
      </div>
      <div *ngIf="tag?.unit || tag?.employee" class="lower">
        <ng-container *ngIf="tag.unit">
          <span>Következő művelet: {{ tag.unit.nextPoi }}</span>
          <span>Létrehozva: {{ tag.unit.createdAt }}</span>
        </ng-container>
        <ng-container *ngIf="tag.employee">
          <span>Utolsó pozíció: {{ tag.employee.lastPosition }}</span>
          <span>Mozgás: {{ tag.employee.lastMovement }}</span>
        </ng-container>
      </div>
    </div>
    <div *ngIf="template !== PopupTemplate.IdentifyTag" class="bottom">
      <button (click)="selectAnother()" *ngIf="template === PopupTemplate.UnknownTag"
              class="with-icon light">
        <span class="btn-icon"><img alt="tag-icon" src="assets/img/tag.svg"></span><span i18n>Select another tag</span>
      </button>
      <button (click)="close()" *ngIf="template === PopupTemplate.UnknownTag" class="with-icon light">
        <i class="material-icons">close</i><span i18n>Cancel</span>
      </button>
      <a *ngIf="template === PopupTemplate.AssignedToWorker"
         [routerLink]="'/employees/tracking/' + tag.employee.id + '/logbook'" class="button with-icon light">
        <i class="material-icons">list</i><span i18n>Details</span>
      </a>
      <a *ngIf="template === PopupTemplate.AssignedToWorker" [queryParams]="{employee: tag.employee.id}"
         [routerLink]="'/employees/tracking/map'" class="button with-icon light">
        <i class="material-icons">gps_not_fixed</i><span i18n>Tracking</span>
      </a>
      <button (click)="goDetails()" *ngIf="template === PopupTemplate.AssignedToUnit" class="with-icon light">
        <i class="material-icons">list</i><span i18n>Details</span>
      </button>
      <button (click)="goTracking()" *ngIf="template === PopupTemplate.AssignedToUnit" class="with-icon light">
        <i class="material-icons">gps_not_fixed</i><span i18n>Tracking</span>
      </button>
      <button (click)="tagChange()" *ngIf="template === PopupTemplate.AssignedToUnit" class="with-icon light">
        <i class="material-icons red">swap_calls</i><span i18n>Tag replace</span>
      </button>
      <button (click)="close()"
              *ngIf="template === PopupTemplate.NotAssigned || template === PopupTemplate.AssignedToWorker || template === PopupTemplate.AssignedToUnit"
              class="with-icon green pull-right">
        <i class="material-icons">arrow_forward</i><span i18n>Finish</span>
      </button>
    </div>
  </div>
</div>
