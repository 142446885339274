import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {CustomAuthModule} from '@innobile/authmodule';
import {SharedModule} from './shared/shared.module';
import {CompanyUser} from './models/CompanyUser';
import {CompaniesInterceptor} from './shared/interceptors/companies.interceptor';
import {SharedVariablesService} from './services/shared-variables.service';
import {TableWebSocketAdapter} from '@innobile/tablemodule';
import {TableSocketAdapterService} from './services/table-socket-adapter.service';
import {NotfoundInterceptor} from './shared/interceptors/notfound.interceptor';
import {PasswordResetComponent} from './password-reset/password-reset.component';

declare const require; // Use the require method provided by webpack

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordResetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    CustomAuthModule.forRoot({
      config: {
        apiPrefix: environment.apiPrefix,
        userModel: CompanyUser,
        whitelistedApiCalls: ['authentication', 'users/reset']
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompaniesInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotfoundInterceptor,
      multi: true
    }, {
      provide: TableWebSocketAdapter,
      useClass: TableSocketAdapterService
    },
    SharedVariablesService
  ]
})
export class AppModule {
}
