import {Pipe, PipeTransform} from '@angular/core';
import {IDropdownElement} from './dropdown-element.interface';

/**
 * Legördülő elemválasztó komponens adatainak szűrése ID-k alapján
 */
@Pipe({
    name: 'dropdownFilter',
    pure: true
})
export class DropdownFilterPipe implements PipeTransform {
    /**
     * Kiszűri a listából azokat az elemeket, amiknek az azonosítója át lett adva
     * @param dropdownElements - elemek teljes listája
     * @param hiddenIds - kiszűrendő azonosítók
     * @returns - szűrt lista
     */
    transform(dropdownElements: IDropdownElement[], hiddenIds: number[]): IDropdownElement[] {
      return dropdownElements.filter(element => hiddenIds.indexOf(element.id) < 0);
    }

}
