import {Component} from '@angular/core';
import {BarcodeBaseComponent} from '../barcode-base/barcode-base.component';
import {HttpClient} from '@angular/common/http';
import {TagReplaceService} from '../../services/tag-replace.service';
import {Router} from '@angular/router';

enum SearchPopupTemplate {
  IdentifyTag = 1,
  UnknownTag,
  NotAssigned,
  BatteryLow,
  Assigned,
  AssignedToWorker,
  AssignedToUnit
}

export interface ISearchTagData {
  id?: number;
  name: string;
  battery: number;
  unit?: IUnitAssignment | null;
  employee?: IEmployeeAssignment | null;
}

export interface IUnitAssignment {
  id: number;
  planId: number;
  name: string;
  quantity: number;
  nextPoi?: string | null;
  createdAt?: string | null;
}

export interface IEmployeeAssignment {
  id: number;
  name: string;
  lastPosition?: string | null;
  lastMovement?: string | null;
}

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss']
})
export class TagSearchComponent extends BarcodeBaseComponent {
  PopupTemplate = SearchPopupTemplate;
  template: SearchPopupTemplate | null = null;
  tag: ISearchTagData | null = null;
  tagIdentifier = '';

  constructor(private http: HttpClient, private tagService: TagReplaceService, private router: Router) {
    super();
  }

  openPopup() {
    this.template = SearchPopupTemplate.IdentifyTag;
  }

  handleBarcode(barcode: string): void {
    if (this.template) {
      super.handleBarcode(barcode);
      if (!this.tagIdentifier.length || this.template !== SearchPopupTemplate.IdentifyTag) {
        this.tagIdentifier = barcode;
      }
      this.identify();
    }
  }

  selectAnother() {
    this.tagIdentifier = '';
    this.template = SearchPopupTemplate.IdentifyTag;
  }

  identify() {
    if (this.template !== null) {
      this.http.post('tags/search', {tagId: this.tagIdentifier}).subscribe((resp: ISearchTagData) => {
          this.tag = resp;
          if (this.tag.unit) {
            this.template = SearchPopupTemplate.AssignedToUnit;
          } else if (this.tag.employee) {
            this.template = SearchPopupTemplate.AssignedToWorker;
          } else {
            this.template = SearchPopupTemplate.NotAssigned;
          }
        },
        (error) => {
          this.tag = null;
          if (error.error.status === 'UnknownTag') {
            this.template = SearchPopupTemplate.UnknownTag;
          }
        });
    }
  }

  goTracking() {
    this.router.navigate([`/manufacturing/production_plans/${this.tag.unit.planId}/tracking`], {queryParams: {unit: this.tag.unit.id}});
    this.close();
  }

  goDetails() {
    this.router.navigate([`/manufacturing/production_plans/${this.tag.unit.planId}/units`]);
    this.close();
  }

  tagChange() {
    this.tagService.open(this.tagIdentifier);
    this.close();
  }

  overlayClicked(): void {
    this.close();
  }

  handlePopupClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }


  close(): void {
    this.template = null;
    this.tag = null;
    this.tagIdentifier = '';
  }
}
