import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BarcodeBaseComponent} from '../barcode-base/barcode-base.component';
import {TagReplaceService} from '../../services/tag-replace.service';
import {Router} from '@angular/router';

export enum ReplacePopupTemplate {
  IdentifyTag1 = 1,
  IdentifyTag2 = 2,
  UnknownTag,
  UnknownTag2,
  NotAssigned,
  OnlyWorker,
  OnlyWorker2,
  Assigned,
  Success
}

export interface IReplaceTagData {
  id?: number;
  name: string;
  battery: number;
  assignment?: IUnitAssignment | null;
}

export interface IUnitAssignment {
  id: number;
  identifier: string;
  quantity: number;
  planId?: number;
}

@Component({
  selector: 'app-tag-replace',
  templateUrl: './tag-replace.component.html',
  styleUrls: ['./tag-replace.component.scss']
})
export class TagReplaceComponent extends BarcodeBaseComponent implements OnInit {
  PopupTemplate = ReplacePopupTemplate;
  template: ReplacePopupTemplate | null = null;
  tag: IReplaceTagData | null = null;
  tagIdentifier = '';
  tagIdentifier2 = '';

  constructor(private http: HttpClient, private tagService: TagReplaceService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.tagService.getCurrentTag().subscribe(tag => {
      if (tag === null) {
        if (this.template !== null) {
          this.close();
        }
      } else if (this.template === null) {
        this.tagIdentifier = tag;
        this.template = ReplacePopupTemplate.IdentifyTag1;
        if (this.tagIdentifier.length) {
          this.identify(1);
        }
      }
    });
  }

  goTracking() {
    this.router.navigate([`/manufacturing/production_plans/${this.tag.assignment.planId}/tracking`],
      {queryParams: {unit: this.tag.assignment.id}});
    this.close();
  }

  goDetails() {
    this.router.navigate([`/manufacturing/production_plans/${this.tag.assignment.planId}/units`]);
    this.close();
  }

  handleBarcode(barcode: string): void {
    super.handleBarcode(barcode);
    if (this.template === ReplacePopupTemplate.IdentifyTag1) {
      if (!this.tagIdentifier.length) {
        this.tagIdentifier = barcode;
      }
      this.identify(1);
    } else if (this.template === ReplacePopupTemplate.IdentifyTag2) {
      if (!this.tagIdentifier2.length) {
        this.tagIdentifier2 = barcode;
      }
      this.identify(2);
    }
  }

  identify(step: number) {
    if (this.template !== null) {
      this.http.post('tags/replace', {
        oldTagId: this.tagIdentifier,
        newTagId: step === 2 ? this.tagIdentifier2 : null
      }).subscribe((resp: { tag: IReplaceTagData }) => {
          this.tag = resp.tag;
          if (step === 2) {
            this.template = ReplacePopupTemplate.Success;
          } else {
            if (this.tag.assignment) {
              this.template = ReplacePopupTemplate.IdentifyTag2;
            } else {
              this.template = ReplacePopupTemplate.NotAssigned;
            }
          }
        },
        (error) => {
          if (error.error.status === 'UnknownTag') {
            if (step === 2) {
              this.template = ReplacePopupTemplate.UnknownTag2;
            } else {
              this.template = ReplacePopupTemplate.UnknownTag;
            }
          } else if (error.error.status === 'TagError') {
            if (step === 2) {
              this.template = ReplacePopupTemplate.OnlyWorker2;
            } else {
              this.template = ReplacePopupTemplate.OnlyWorker;
            }
          } else if (error.error.status === 'NotAssigned') {
            this.template = ReplacePopupTemplate.NotAssigned;
          } else if (error.error.status === 'Assigned') {
            this.template = ReplacePopupTemplate.Assigned;
          }
        });
    }
  }

  selectAnother(step: number) {
    if (step === 1) {
      this.tagIdentifier = '';
      this.template = ReplacePopupTemplate.IdentifyTag1;
    } else if (step === 2) {
      this.tagIdentifier2 = '';
      this.template = ReplacePopupTemplate.IdentifyTag2;
    }
  }

  overlayClicked(): void {
    this.close();
  }

  handlePopupClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }


  close(): void {
    this.template = null;
    this.tag = null;
    this.tagIdentifier = '';
    this.tagIdentifier2 = '';
    this.tagService.close();
  }
}
