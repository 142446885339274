import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {unitOfTime} from 'moment';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit {
  private defTypes = [
    {id: 'off', value: $localize`All`},
    {id: 'absolute', value: $localize`Day`},
    {id: 'period', value: $localize`Period`},
    {id: 'relative', value: $localize`Relative`},
  ];
  typeOpen = false;
  invalid = false;
  @Input() enabled = false;
  @Output() enabledChange = new EventEmitter<boolean>();
  @Input() startDate = moment().startOf('day').subtract(2, 'months');
  @Output() startDateChange = new EventEmitter<moment.Moment>();
  @Input() endDate = moment();
  @Output() endDateChange = new EventEmitter<moment.Moment>();
  @Output() dateChange = new EventEmitter<{ startDate: moment.Moment, endDate: moment.Moment }>();
  @Input() types = this.defTypes;
  @Input() type = this.types[2];
  @Output() typeChange = new EventEmitter<{ id: string, value: string }>();
  @Input() hasSwitch = true;

  @Input() defPeriodValue?: number;

  relativePeriodOpen = false;
  @Input() relativePeriods = [
    {id: 'hours', value: $localize`Hours`},
    {id: 'days', value: $localize`Days`},
    {id: 'weeks', value: $localize`Weeks`},
    {id: 'months', value: $localize`Months`},
  ];
  @Input() relativePeriod = this.relativePeriods[3];
  @Input() relativePeriodValue = 2;
  relativePeriodValueChanged: Subject<number> = new Subject<number>();

  constructor() {
  }

  ngOnInit(): void {
    this.relativePeriodValueChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(model => {
        this.relativePeriodValue = model;
        this.startDate = moment().subtract(this.relativePeriodValue, this.relativePeriod.id as unitOfTime.DurationConstructor);
        this.endDate = moment();
        this.emitDates();
      });

    if (!this.hasSwitch && this.types == this.defTypes) {
      this.types = this.types.filter(elem => elem.id !== 'off');
    }
    this.hasSwitch = !!this.types.find((elem: { id: string, value: string }) => elem.id === 'off');

    if (!this.hasSwitch) {
      this.enabled = true;
    } else {
      if (!this.enabled) {
        this.type = this.types.find((elem: { id: string, value: string }) => elem.id === 'off');
      }
    }

    if (this.defPeriodValue) {
      this.type = this.types.find(type => type.id === 'relative');
      this.relativePeriodValue = this.defPeriodValue;
      this.startDate = moment().subtract(this.relativePeriodValue, this.relativePeriod.id as unitOfTime.DurationConstructor);
      this.endDate = moment();
    }
  }

  typeSelect(event: { id: string, value: string }) {
    this.type = event;
    this.typeOpen = false;
    this.enabled = true;
    this.invalid = false;

    switch (this.type.id) {
      case 'off':
        this.enabled = false;
        break;
      case 'absolute':
        this.startDate = moment().startOf('day');
        this.endDate = moment().endOf('day');
        break;
      case 'period':
        this.startDate = moment().startOf('day').subtract(1, 'week');
        this.endDate = moment().endOf('day');
        break;
      case 'relative':
        this.startDate = moment().subtract(this.relativePeriodValue, this.relativePeriod.id as unitOfTime.DurationConstructor);
        this.endDate = moment();
        break;
    }
    this.enabledChange.emit(this.enabled);
    this.emitDates();
  }

  periodSelect(event: { id: string, value: string }) {
    this.relativePeriod = event;
    this.relativePeriodOpen = false;

    this.startDate = moment().subtract(this.relativePeriodValue, this.relativePeriod.id as unitOfTime.DurationConstructor);
    this.endDate = moment();
    this.emitDates();
  }

  relativePeriodValueChange(event: number) {
    if (event > 0) {
      this.relativePeriodValueChanged.next(event);
    }
  }

  emitDates() {
    this.startDateChange.emit(this.startDate);
    this.endDateChange.emit(this.endDate);
    this.dateChange.emit({startDate: this.startDate, endDate: this.endDate});
  }

  selectStartDate(event: string) {
    this.startDate = moment(event).startOf('day');
    this.invalid = this.startDate && this.endDate && this.startDate.isAfter(this.endDate) && this.type.id === 'period';
    if (this.type.id === 'absolute') {
      this.endDate = moment(event).endOf('day');
    }
    this.emitDates();
  }

  selectEndDate(event: string) {
    this.endDate = moment(event).endOf('day');
    this.invalid = this.startDate && this.endDate && this.startDate.isAfter(this.endDate) && this.type.id === 'period';
    this.emitDates();
  }

  prevDate() {
    this.selectStartDate(this.startDate.subtract(1, 'day').format('YYYY-MM-DD'));
  }

  nextDate() {
    this.selectStartDate(this.startDate.add(1, 'day').format('YYYY-MM-DD'));
  }

  nextDisabled() {
    return this.startDate.diff(moment(), 'days') >= 0;
  }
}
