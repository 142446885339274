<ng-template #defTemplate let-element>
  <span class="default-template">{{ element }}</span>
</ng-template>

<div (click)="toggleDropdown()" [class.disabled]="disabled" [class.open]="isOpen" class="dropdown-container">
  <span class="dropdown-placeholder" *ngIf="!selectedElement">{{ placeholder }}</span>
  <ng-container *ngIf="selectedElement" [ngTemplateOutlet]="template ? template : defTemplate" [ngTemplateOutletContext]="{$implicit: selectedElement}">
  </ng-container>
  <div class="dropdown-controls">
    <i class="material-icons toggle">{{ isOpen ? 'expand_less' : 'expand_more' }}</i>
    <i class="material-icons cancel" (click)="cancel($event)" *ngIf="cancelable">close</i>
  </div>
  <ul class="dropdown-body">
    <li (click)="selectElement(dropdownElement)"
        *ngFor="let dropdownElement of list">
      <ng-container *ngTemplateOutlet="template ? template : defTemplate; context: {$implicit: dropdownElement}">
      </ng-container>
    </li>
  </ul>
</div>
