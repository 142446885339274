<div [class.invalid]="type.id === 'period' && invalid" class="datepicker-block">
  <div *ngIf="types?.length > 1" [ngClass]="{'open': typeOpen}" class="dropdown-container date">
    <div (click)="typeOpen = !typeOpen" class="input">
      <span class="text">{{ type.value }}</span>
      <span class="material-icons down">keyboard_arrow_down</span>
      <span class="material-icons up">keyboard_arrow_up</span>
    </div>
    <ul class="dropdown-body">
      <li (click)="typeSelect(forType)" *ngFor="let forType of types" [ngClass]="{'checked': type === forType}">
        <div class="checkbox">
          <i class="material-icons">check</i>
        </div>
        <span class="title">{{ forType.value }}</span>
      </li>
    </ul>
  </div>
  <ng-container *ngIf="type.id === 'relative'">
    <div class="dropdown-container date disabled">
      <div class="input">
        <span class="text" i18n>The last</span>
        <span class="material-icons down">keyboard_arrow_down</span>
        <span class="material-icons up">keyboard_arrow_up</span>
      </div>
    </div>
    <label for="relativePeriodValue">
      <input (ngModelChange)="relativePeriodValueChange($event)" [(ngModel)]="relativePeriodValue"
             id="relativePeriodValue" type="number">
    </label>
    <div [ngClass]="{'open': relativePeriodOpen}" class="dropdown-container date">
      <div (click)="relativePeriodOpen = !relativePeriodOpen" class="input">
        <span class="text">{{ relativePeriod.value }}</span>
        <span class="material-icons down">keyboard_arrow_down</span>
        <span class="material-icons up">keyboard_arrow_up</span>
      </div>
      <ul class="dropdown-body">
        <li>
          <span class="title bold" i18n>Period</span>
        </li>
        <li (click)="periodSelect(period)" *ngFor="let period of relativePeriods"
            [ngClass]="{'checked': relativePeriod === period}">
          <div class="checkbox">
            <i class="material-icons">check</i>
          </div>
          <span class="title">{{ period.value }}</span>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="type.id === 'absolute'">
    <button (click)="prevDate()" class="btn">
      <span class="material-icons">keyboard_arrow_left</span>
    </button>
    <div class="btn date start-date">
      <input (ngModelChange)="selectStartDate($event)" [ngModel]="startDate.format('YYYY-MM-DD')" required type="date">
    </div>
    <button (click)="nextDate()" [disabled]="nextDisabled()" class="btn">
      <span class="material-icons">keyboard_arrow_right</span>
    </button>
  </ng-container>
  <ng-container *ngIf="type.id === 'period'">
    <div class="btn date start-date">
      <span class="material-icons">keyboard_capslock</span>
      <input (ngModelChange)="selectStartDate($event)" [ngModel]="startDate.format('YYYY-MM-DD')" required type="date">
    </div>
    <div class="btn date end-date">
      <span class="material-icons">last_page</span>
      <input (ngModelChange)="selectEndDate($event)" [ngModel]="endDate.format('YYYY-MM-DD')" required type="date">
    </div>
  </ng-container>
  <ng-container *ngIf="type.id === 'off'">
    <div class="btn date disabled" i18n>All-time data shown</div>
  </ng-container>
</div>
