import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  get Language(): string {
    return $localize`:@@accept_language:en`;
  }
}
