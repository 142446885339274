import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '@innobile/authmodule';
import {CompanyUser} from '../../models/CompanyUser';
import {environment} from '../../../environments/environment';
import {LanguageService} from "../../services/language.service";

@Injectable()
export class CompaniesInterceptor implements HttpInterceptor {
  private user: CompanyUser;

  constructor(private userService: UserService, private languageService: LanguageService) {
    this.userService.userSubject.subscribe((user: CompanyUser) => {
      this.user = user;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiPrefix) && this.user) {
      const companies = this.user.Companies;
      if (companies) {
        const sel = [];
        for (let i = 0, k = companies.length; i !== k; i++) {
          if (companies[i].selected) {
            sel.push(companies[i].id);
          }
        }
        req = req.clone({
          headers: req.headers.append('x-pozi-company', JSON.stringify(sel)).append('Accept-Language', this.languageService.Language)
        });
      }
    }
    return next.handle(req);
  }
}
