import {User} from '@innobile/authmodule';
import {IDropdownElement} from '../shared/dropdown/dropdown-element.interface';

export class CompanyUser extends User {
  private companies: IDropdownElement[];
  multiCompany = false;

  constructor(raw) {
    super(raw);
    this.companies = raw.companies.map(c => c.title ? c : ({title: c.name, id: c.id}));
    const localCompanies = localStorage.getItem('selectedCompanies');
    let selectedCompanies: number[] = null;
    try {
      selectedCompanies = localCompanies ? JSON.parse(localCompanies) : null;
    } catch (ex) {
      localStorage.removeItem('selectedCompanies');
      selectedCompanies = null;
    }
    if (selectedCompanies === null) {
      this.companies[0].selected = true;
    } else {
      this.companies.forEach(c => {
        c.selected = selectedCompanies.indexOf(c.id) >= 0;
      });
    }

    this.multiCompany = raw.multiCompany;
  }

  destroy() {
    localStorage.removeItem('selectedCompanies');
  }

  get Companies(): IDropdownElement[] {
    return this.companies;
  }

  set Companies(companies: IDropdownElement[]) {
    this.companies = companies;
    localStorage.setItem('selectedCompanies', JSON.stringify(this.companies.filter(c => c.selected).map(c => c.id)));
  }

  get SelectedCompanies() {
    return this.companies.filter((c) => {
      return c.selected;
    }).map(c => c.id);
  }
}
