<input (focus)="drawTippy()" [formControl]="formControl" [placeholder]="placeholder || ''" tabindex="0" type="text">
<i class="material-icons" (click)="drawTippy()">{{ open ? 'expand_less' : 'expand_more' }}</i>

<ng-template #optionList let-options>
  <div class="options">
    <div class="option-container" *ngFor="let option of options; let idx = index"
         [class.focused]="idx === focusedOption" (click)="selectValue(option)">
      <ng-container *ngIf="displayTemplate; else defaultTemplate" [ngTemplateOutlet]="displayTemplate"
                    [ngTemplateOutletContext]="{option: option, idx: idx}"></ng-container>
      <ng-template #defaultTemplate let-opt="option">
        <span class="defaultTemplate" [innerHTML]="displayWith(opt) | highlight:formControl.value">
      </span>
      </ng-template>
    </div>
    <span class="use" *ngIf="!selectOnly && formControl.value" (click)="selectTyped()">
      Use "{{ formControl.value }}"
    </span>
  </div>
</ng-template>
