import {Component, forwardRef, Input, TemplateRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagPickerComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => TagPickerComponent),
    }
  ]
})
export class TagPickerComponent implements ControlValueAccessor, Validator {

  @Input() tagList?: any[] = null;
  @Input() tagTemplate?: TemplateRef<any>;
  @Input() limit?: number = null;
  @Input() placeholder = $localize`:@@tagpicker.placeholder:Type new item`;
  @Input() hideControls = false;
  @Input() inputType: 'dropdown' | 'typeahead' | 'text' = 'text';
  @Input() addingModeWhenEmpty = false;
  @Input() typeaheadSelectOnly = false;
  @Input() typeaheadDisplayWith: ((value: any) => string) = (value: any) => {
    return value ? value.toString() : ''
  };
  disabled = false;
  selectedTags: any[] = [];
  isAdding = false;
  tagToAdd: any | null = null;

  constructor() {
  }

  get UnaddedTags(): any[] {
    return this.tagList.filter(tag => !this.selectedTags?.includes(tag));
  }

  onTouched = () => {
  }
  onChange = (value) => {
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(tags: any[]): void {
    this.selectedTags = tags;
  }

  removeTag(tag: any) {
    this.selectedTags.splice(this.selectedTags.findIndex(stag => stag === tag), 1);
    this.onTouched();
    this.onChange(this.selectedTags);
  }

  add() {
    if (this.limit === null || this.selectedTags.length < this.limit) {
      this.tagToAdd = null;
      this.isAdding = true;
    }
  }

  addSelectedTag() {
    if (!this.selectedTags) {
      this.selectedTags = [];
    }
    this.selectedTags.push(this.tagToAdd);
    this.isAdding = false;
    this.onTouched();
    this.onChange(this.selectedTags);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (this.limit !== null && (control.value as Array<any>).length > this.limit) ? {limitExceeded: true} : null;
  }

  cancelAdd() {
    this.tagToAdd = null;
    this.isAdding = false;
  }

  removeAll() {
    this.selectedTags = [];
  }

  typeaheadTagSelected(tag: any) {
    this.tagToAdd = tag;
    this.addSelectedTag();
  }
}
