<div (click)="click($event)" *ngIf="visible" class="popup-overlay">
  <div class="popup">
    <div class="titlebar"><span class="icon">
        <ng-content select=".popup-icon"></ng-content></span><span class="title">
        <ng-content select=".popup-title"></ng-content></span><span (click)="close()"
                                                                    class="material-icons">close</span></div>
    <div class="content">
      <ng-content select=".popup-content"></ng-content>
    </div>
    <div class="footer">
      <ng-content select=".popup-footer"></ng-content>
    </div>
  </div>
</div>
