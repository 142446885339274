import {NgModule} from '@angular/core';
import {DropdownComponent} from './dropdown/dropdown.component';
import {CommonModule} from '@angular/common';
import {DropdownFilterPipe} from './dropdown/dropdown-filter.pipe';
import {MultiselectDropdownComponent} from './multiselect-dropdown/multiselect-dropdown.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InlineSVGModule} from 'ng-inline-svg';
import {CustomTableModule, TABLE_CONFIG} from '@innobile/tablemodule';
import {HighlightPipe} from './highlight.pipe';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {PopupComponent} from './popup/popup.component';
import {environment} from '../../environments/environment';
import {TippyDirective} from './tippy.directive';
import {ContextMenuComponent} from './context-menu/context-menu.component';
import {RouterModule} from '@angular/router';
import {PeriodSelectorComponent} from './period-selector/period-selector.component';
import {TagSearchComponent} from './tag-search/tag-search.component';
import {TagReplaceComponent} from './tag-replace/tag-replace.component';
import {BarcodeBaseComponent} from './barcode-base/barcode-base.component';
import {TagPickerComponent} from './tag-picker/tag-picker.component';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {TypeaheadComponent} from './autofill/typeahead.component';
import {Popup2Component} from './popup2/popup2.component';
import {TriStateSwitchComponent} from './tri-state-switch/tri-state-switch.component';
import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {AutocompleteWithCustomComponent} from './autocomplete-with-custom/autocomplete-with-custom.component';
import {ControlRequiredPipe} from './control-required.pipe';
import {LanguageService} from "../services/language.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    CustomTableModule,
    RouterModule,
    CdkScrollableModule
  ],
  declarations: [
    DropdownComponent,
    DropdownFilterPipe,
    MultiselectDropdownComponent,
    HighlightPipe,
    CheckboxComponent,
    PopupComponent,
    TippyDirective,
    ContextMenuComponent,
    PeriodSelectorComponent,
    TagSearchComponent,
    TagReplaceComponent,
    BarcodeBaseComponent,
    TagPickerComponent,
    TypeaheadComponent,
    Popup2Component,
    TriStateSwitchComponent,
    AutocompleteComponent,
    AutocompleteWithCustomComponent,
    ControlRequiredPipe
  ],
  exports: [
    DropdownComponent,
    DropdownFilterPipe,
    MultiselectDropdownComponent,
    InlineSVGModule,
    CustomTableModule,
    HighlightPipe,
    PopupComponent,
    TippyDirective,
    ContextMenuComponent,
    PeriodSelectorComponent,
    TagSearchComponent,
    TagReplaceComponent,
    TagPickerComponent,
    TypeaheadComponent,
    Popup2Component,
    TriStateSwitchComponent,
    CheckboxComponent,
    AutocompleteComponent,
    AutocompleteWithCustomComponent,
    ControlRequiredPipe
  ],
  providers: [{
    provide: TABLE_CONFIG,
    useFactory: initTable,
    deps: [LanguageService]
  }]
})
export class SharedModule {
  constructor() {
  }

}

export function initTable(languageService: LanguageService) {
  return {backendUrl: environment.apiPrefix, language: languageService.Language}
}
